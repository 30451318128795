// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment: any = {
  configApiUrl: '/api/clientConfig',

  //for local development only, do NOT check in
  //configApiUrl: 'https://d-c-liehadmin-ui-01.azurewebsites.net/api/clientConfig',
  //configApiUrl: 'https://e6b7194c-9681-447b-9239-e72934d0f0d9.mock.pstmn.io/api/clientConfig',

  production: false,
  devEnvironment: true
  
};

